<template>
  <div class="visualization-monitor-index-outer">
    <div :style="'background-image: url(' + pageBg + ');background-size: cover'" class="visualization-monitor-index" id="main">
      <div class="login-container loginForm-css" :style="'background-image: url(' + loginTest + ');background-size: cover'">
        <div class="title-row">智能监控</div>
        <form >
          <div :style="'background-image: url(' + loginTestFrom + ');background-size: cover'" class="username-container form-input flex align-center marginTop40">
            <img src="@/static/img/login/user.png">
            <el-input placeholder="账号" v-model="loginInfo.username" />
          </div>
          <div :style="'background-image: url(' + loginTestFrom + ');background-size: cover'" class="password-container form-input flex align-center marginTop20px">
            <img src="@/static/img/login/psd.png">
            <el-input placeholder="密码" type="password" v-model="loginInfo.password" />
          </div>
        </form>
        <div class="button-container" :style="'background-image: url(' + loginTestButton + ');background-size: cover'" @click="onSubmit">
          登&nbsp;&nbsp;录
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {login} from "@/api/api";

export default {
  name: "visualizationLogin",
  data(){
    return {
      loginTest: require('@/static/img/monitor/login-test.png'),
      loginTestButton: require('@/static/img/monitor/login-test-button.png'),
      loginTestFrom: require('@/static/img/monitor/login-form-test.png'),
      pageBg: require('@/static/img/bg.jpg'),
      loginInfo:{
        username:'',
        password:''
      },
    }
  },
  mounted() {
    // 执行自适应
    this.handleScreenAuto();
    //绑定自适应函数 ---防止浏览器栏变化后不再适配
    window.onresize = () => this.handleScreenAuto();

    // this.loginInfo.username = ''
    // this.loginInfo.password = ''
  },
  methods: {
    handleScreenAuto() {
      const designDraftWidth = 1920; //设计稿的宽度
      const designDraftHeight = 1080; //设计稿的高度
      //根据屏幕的变化适配的比例
      const scale = document.documentElement.clientWidth / document.documentElement.clientHeight < designDraftWidth / designDraftHeight
          ? document.documentElement.clientWidth / designDraftWidth
          : document.documentElement.clientHeight / designDraftHeight;
      //缩放比例
      document.querySelector('#main').style.transform = `scale(${scale}) translate(-50%,-50%)`;
    },
    onSubmit(){
      if(!this.loginInfo.username){
        this.$message.error('请输入用户名');
        return false;
      }
      if(!this.loginInfo.password){
        this.$message.error('请输入密码');
        return false;
      }
      login(this.loginInfo).then(res => {
        if(res.success){
          this.$message.success(res.message)
          window.localStorage.setItem('token',res.data);
          this.$root.useRouter.replace("/visualization/index");
        }else{
          this.$message.error(res.message)
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.visualization-monitor-index-outer {
  height: 100vh;
  overflow: hidden;
  background-color: #000;

  .visualization-monitor-index {
    background-color: #090e24;
    width: 1920px;
    height: 1080px;
    color: #FFFFFF;
    text-align: center;

    display: flex;
    justify-content: center;
    align-items: center;

    // 缩放
    overflow: hidden;
    transform-origin: 0 0;
    position: relative;
    left: 50%;
    top: 50%;

    //img {
    //  -webkit-user-drag: none;
    //}

    .login-container{
      position: relative;
      width: 600px;
      height: 450px;
      background-color: #001633 !important;

      .title-row{
        position: relative;
        top: 50px;
        font-size: 24px;
        color: rgba(0, 204, 255, 1);
        font-family: YouSheBiaoTiHei;
      }
    }
  }
}

.loginForm-css{
  .form-input{
    margin: 0 auto 20px;
    border-radius: 8px;
    padding: 0 16px;
    width: 378px;
    height: 60px;
    input{
      border: 0;outline: none;
      padding: 0 10px;
      width:100%;
      background-color: #001633 !important;
      color: rgba(0, 252, 255, 0.4) !important;
      font-size: 18px;

      //&:-webkit-autofill{
      //  -webkit-text-fill-color: #001633 !important;
      //  -webkit-box-shadow: 0 0 0 1000px transparent  inset !important;
      //  background-color:transparent;
      //  background-image: none;
      //  transition: background-color 50000s ease-in-out 0s;
      //}

      input:-webkit-autofill {
        -webkit-box-shadow:0 0 0 1000px white inset;
        -webkit-text-fill-color: #333;
      }
    }
  }
  .username-container{
    position: absolute;
    top: 123.71px;
    left: 102.91px;
  }
  .password-container{
    position: absolute;
    top: 206.17px;
    left: 102.91px;
  }
  .button-container{
    position: absolute;
    top: 308.37px;
    left: 102.91px;

    display: flex;
    justify-content: center;
    align-items: center;

    width: 410px;
    height: 53.78px;
    font-family: YouSheBiaoTiHei;
    font-size: 28px;

    cursor: pointer;
  }
}
</style>